<template>
	<div class="relative w-dvw max-w-2xl mx-auto h-dvh min-h-dvh max-h-dvh flex flex-col scrollbar-hide text-dark-100 touch-pan-y">

		<Head :title="seoData.title">
			<meta :content="seoData.description" name="description">
		</Head>

		<ToastList/>

		<div class="relative w-full flex-1 h-full overflow-y-scroll scrollbar-hide">
			<slot/>
		</div>

		<div class="w-full h-14 bg-dark-900 exclude-poppit">
			<AppMenu/>
		</div>

		<InstallPWA/>
		<BackButtonAd/>
	</div>
</template>

<script setup>
import { computed, onMounted, ref } from 'vue'
import AppMenu from '@/Shared/AppMenu.vue'
import ToastList from '@/Components/ToastNotifications/ToastList.vue'
import {Head, router, usePage} from '@inertiajs/vue3'
import InstallPWA from '@/Shared/InstallPWA.vue'
import BackButtonAd from '@/Shared/BackButtonAd.vue'
import { MasterOfPoppits } from '@/Modules/master-of-poppits'
import { useAnalyticsStore } from '@/Stores/AnalyticsStore'
import { useWpaInstallStore } from '@/Stores/WpaInstallStore.js'

const seoData = ref(usePage().props.seoData);
const analyticsStore = useAnalyticsStore();
const settings = ref(usePage().props.settings)
const wpaInstallStore = useWpaInstallStore();
const referrer = ref('default');
const pagesWithNoAds = computed(() =>
	['/login', '/register', '/app', '/user', '/reset-password', '/forgot-password', '/email']
);

onMounted(() => {

	// listen for the guest account created event
	Echo.channel('App.Guest.' + usePage().props.guestId)
		.listen('.guest-account-created', (e) => {
			console.log('Guest account created', e);
			router.reload({only: ['auth']});
		});

	loadAds();
})

const loadAds = () => {

	// skip if the user on these pages
	if (pagesWithNoAds.value.some(path => window.location.pathname.startsWith(path))) {
		return;
	}

	// Get the URL parameters
	const urlParams = new URLSearchParams(window.location.search);

	// Check if 'ref' parameter is present and 'ref' is not already in session storage
	if (urlParams.has('ref') && !sessionStorage.getItem('ref')) {
		// Store 'ref' parameter in session storage
		sessionStorage.setItem('ref', urlParams.get('ref'));
		referrer.value = urlParams.get('ref');
	}

	window.masterOfPoppits = new MasterOfPoppits({
		initialDelay: settings.value.pop_initial_delay || 5,
		poppitInterval: settings.value.pop_interval || 60,
		poppitUrls: [
			{ url: 'https://revive.videobaba.xyz/revive/www/admin/plugins/redirectAd/redirect.php?zoneid=315', weight: 99 },
			{ url: 'https://www.bollyocean.com/api/direct/450270?s1=3sex', weight: 1 }
		],
		onSuccess: (url) => {
			analyticsStore.sendAnalyticsEvent('popunder', {
				ref: sessionStorage.getItem('ref') || 'default',
				url: url,
				ad_network: getAdNetwork(url),
			});
		},
	});

	setTimeout(() => {
		const script = document.createElement('script');

		// randomize the script 50/50
		const random = Math.random();
		if (random > 0.5) {
			// onclicka script
			script.src = 'https://js.onclckmn.com/static/onclicka.js';
			script.setAttribute('data-admpid', '293199');
		} else {
			// clickadila script
			script.src = 'https://js.wpadmngr.com/static/adManager.js';
			script.setAttribute('data-admpid', '155467');
		}

		script.async = true;
		document.body.appendChild(script);
	}, 6000);
}

const getAdNetwork = (url) => {
	if (url.includes('s.pemsrv.com')) {
		return 'EXO';
	}
	if (url.includes('tsyndicate.com')) {
		return 'Traffic Stars';
	}
	return 'Unknown';
}
</script>
